

.recovery-option-beacon-container {
  display: flex;
  justify-content: center;
}

.recovery-option-beacon-logo {
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  height: 90px;
  width: 90px;
}

.recovery-option-beacon-image {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.recovery-option-fields {
  padding: 0 0 12px;
}

.recovery-option-div {
  width: 100%;
}

div {
  display: block;
}

.recovery-option-button {
  padding-bottom: 20px;
}

button {
  width: 100%;
}

.recovery-option-username {
  padding-bottom: 15px;
}

.recovery-option-fields-container {
  padding: 10px 42px 20px;
}

.recovery-option-logo {
  padding-bottom: 15px;
}
