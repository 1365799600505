.recoveryquestion-authorize-fields {
    padding: 0 0 10px;
    position: relative;
    text-align: left;

}

.recoveryquestion-authorize-div {
    width: 100%;

}

div {
    display: block;
}

.recoveryquestion-authorize-button {
    padding-bottom: 20px;
}

button {
    width: 100%;
}

.recoveryquestion-authorize-username {
    padding-bottom: 15px;
}

.recoveryquestion-authorize-fields-container {
    padding: 0px 42px 10px;
}


.recoveryquestion-authorize-beacon-container {
    display: flex;
    justify-content: center;

}

.recoveryquestion-authorize-beacon-logo {
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    height: 90px;
    width: 90px;
}

.recoveryquestion-authorize-item-logo {
    border-radius: 50%;
    border: 1px solid #d8d8d8;
}

.recoveryquestion-authorize-beacon-image {
    border-radius: 50%;
    height: 100%;
    width: 100%;

}

h3.recoveryquestion-header-help {
    color: #21609F;
}