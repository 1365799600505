.confirmation-beacon-container {
  display: flex;
  justify-content: center;
}

.confirmation-beacon-logo {
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  height: 90px;
  width: 90px;

}

.confirmation-beacon-image {
  border-radius: 50%;
  height: 100%;
  width: 100%;

}

.confirmation-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-top: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: grey;
  background-color: #fff;
}

@media all and (max-width: 390px) {
  .confirmation-container {
    width: 100%;
  }
}

@media all and (min-width: 1024px) {
  .confirmation-container {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.confirmation-fields {
  padding: 0 0 12px;

}

.confirmation-div {
  width: 100%;
}

div {
  display: block;
}

.confirmation-button {
  padding-bottom: 20px;
}

button {
  width: 100%;

}

.confirmation-username {
  padding-bottom: 15px;
}

.confirmation-fields-container {
  padding: 10px 42px 20px;
}

.confirmation-logo {
  padding-bottom: 15px;
}