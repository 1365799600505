.recovery-homepage-beacon-container {
  display: flex;
  justify-content: center;
}

.recovery-homepage-beacon-logo {
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  height: 90px;
  width: 90px;

}

.recovery-homepage-beacon-image {
  border-radius: 50%;
  height: 100%;
  width: 100%;

}

.ql-editor {
  text-align: left;
}

p {
  margin: 0;
}

.recovery-homepage-container {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-top: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  background-color: #fff;
  scroll-behavior: smooth;
}

@media all and (max-width: 390px) {
  .recovery-homepage-container {
    width: 100%;
  }
}

@media screen and (min-height: 1000px) {
  .recovery-homepage-container {
    position: fixed;
  }
}

@media screen and (min-width: 768px) {
  .recovery-homepage-container {
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: grey;
  }
}


.ant-spin-dot-item {
  background-color: black;
}

.recovery-homepage-fields {
  padding: 0 0 12px;

}

.recovery-homepage-div {
  width: 100%;
}

div {
  display: block;
}

.recovery-homepage-button {
  padding-bottom: 20px;
}

button {
  width: 100%;
}

.recovery-homepage-username {
  padding-bottom: 10px;
}

.recovery-homepage-fields-container {
  padding: 10px 42px 20px;
}

.recovery-homepage-logo {
  padding-bottom: 15px;
}