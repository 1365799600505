body {
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-size: 100% 100%;
  height: 100%;
  min-height: 100px;
  background-repeat: no-repeat;
  /* background-image: Url("../public/thiago-gomes-K0YuYMAwCTQ-unsplash.jpg"); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
