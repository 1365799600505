.factor-authorize-container {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-top: 15px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  background-color: #fff;
}

@media all and (max-width: 390px) {
  .factor-authorize-container {
    width: 100%
  }
}

@media screen and (min-height: 780px) {
  .factor-authorize-container {
    position: fixed;
  }
}

@media screen and (min-width: 768px) {
  .factor-authorize-container {
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: grey;
  }
}

.factor-authorize-fields {
  padding: 0 0 10px;
  position: relative;
  text-align: left;

}

.factor-authorize-div {
  width: 100%;

}

div {
  display: block;
}

.factor-authorize-button {
  padding-bottom: 20px;
}

button {
  width: 100%;
}

.factor-authorize-username {
  padding-bottom: 15px;
}

.factor-authorize-fields-container {
  padding: 0px 42px 10px;
}



.factor-authorize-beacon-container {
  display: flex;
  justify-content: center;
}

.factor-authorize-beacon-logo {
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  height: 90px;
  width: 90px;
}

.factor-authorize-item-logo {
  border-radius: 50%;
  border: 1px solid #d8d8d8;
}

.factor-authorize-logo {
  padding-top: 10px;

}

.factor-authorize-list-logo {
  border-radius: 47%;
  border: 1px solid #d8d8d8;
  height: 30px;
}

.factor-authorize-beacon-image {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

h3.factor-header-help {
  color: #21609f;
}



.phone {
  margin: 10px 0;
}

.phone-body {
  background: #666;
  border-radius: 6px;
  display: inline-block;
  margin: 0 auto;
  padding: 12px 3px 0;
}


.phone--screen {
  background: #e5edfb;
  min-width: 50px;
  padding: 15px 0;
}

.phone--number {
  color: #1662dd;
  font-size: 25px;
  padding: 5px;
}

.phone--home-button {
  background: #fff;
  border-radius: 50%;
  height: 10px;
  margin: 3px auto;
  width: 10px;
}